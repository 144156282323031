<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="automatic-test">
    <div
      class="relative h-100"
      style="overflow: hidden"
      :style="!isAddMode && openSlider ? 'height: 1200px;' : ''"
    >
      <div v-if="showAutomaticTest" class="w-100 h-100">
        <div class="manual-top flex items-center box-shadow box-border white">
          <div class="flex items-center p-15 box-border-right" style="width: 149px" title="back">
            <img
              :src="require(`../assets/back-arrow.svg`)"
              class="pointer"
              alt=""
              @click="cancelEditTest()"
            />
            <h3 class="skyblue-text fs-16 fw-700 m-0 nowrap" style="margin-left: 12px">
              Edit Test
            </h3>
          </div>
          <h3
            class="heading dark-text m-0 fs-14 fw-400 pointer nowrap h-100"
            :class="{ active: showInstruction === true }"
            @click="showTestInstruction()"
          >
            Test Instructions
          </h3>
          <div class="sections scroll-horizontal h-100" v-dragscroll="true">
            <h3
              class="heading dark-text m-0 fs-14 fw-400 h-100 pointer nowrap"
              :class="{ active: item.InstituteTestPartId === sectionId }"
              @click="goToSection(item, index)"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              {{ item.SectionName ? item.SectionName : `${"Section-" + (index + 1)}` }}
              <a class="tooltip" data-tooltip="I am a tooltip">
                <img
                  v-if="item.SelectedQuestions !== item.NoOfQuestions"
                  class="warning-icon"
                  :src="require(`../assets/Warning-new.svg`)"
                />
                <span class="tooltiptext"
                  >You have not selected total question of this section.</span
                >
              </a>
            </h3>
          </div>
          <div class="manual-search hide">
            <input type="text" placeholder="Search from questiion" />
            <img class="search_img" :src="require(`../assets/Search.svg`)" alt="Search" />
          </div>
        </div>
        <!-- Test Instructions -->
        <div
          class="previewBody_content"
          v-if="showInstruction === true && testSummary.length !== 0"
        >
          <TestInstructionComponentVue :instituteTestId="instituteTestId" />
        </div>
        <!-- Section Contents -->
        <div class="manual-contents" v-if="showInstruction === false">
          <div class="flex h-100">
            <div class="question-sec h-100">
              <div class="flex flex-between h-100">
                <div class="manual-middle w-100 h-100">
                  <div class="manual-bottom" style="height: 100%; padding: 5px 0 15px">
                    <!--  eslint-disable-next-line vue/no-parsing-error -->
                    <div class="flex flex-between">
                      <h3 class="fs-12 m-0 fw-600 dark-text pd-top18" style="margin-bottom: 18px">
                        Questions: {{ questionList.length }}
                        {{ questionList.length < sectionTotalQns ? "/" + sectionTotalQns : "" }}
                      </h3>
                      <div style="padding-top: 14px; padding-right: 10px">
                        <a class="shuffle-question-button" @click="shuffleQuestionList()"
                          >Shuffle Questions
                        </a>
                      </div>
                    </div>
                    <!-- Questions -->
                    <div class="scroll-verticle h-100">
                      <div
                        v-for="(data, index) in questionList"
                        class="flex"
                        :key="index"
                        style="margin-bottom: 30px"
                      >
                        <div
                          class="index-class"
                          :class="{ active: selectedQuestionIndex === index }"
                        >
                          <span style="margin-top: 5px">{{ index + 1 }}</span>
                        </div>
                        <div
                          class="question-image relative"
                          :class="{
                            active: selectedQuestionIndex === index,
                            easy: data.DifficultyGroup === 'Easy',
                            normal: data.DifficultyGroup === 'Normal',
                            hard: data.DifficultyGroup === 'Hard',
                          }"
                          @click="questionDetails(data, index)"
                          v-if="data.Q_LATEX_Status === 'Y'"
                        >
                          <div
                            style="padding: 15px 10px; border-radius: 4px"
                            class="white"
                            v-if="data.Q_LATEX_Status === 'Y'"
                          >
                            <vue-mathjax
                              :formula="data.Q_Latex"
                              :options="VueMathJaxOptions"
                              :safe="false"
                              class="space-break fs-18 fw-500"
                            />
                            <div>
                              <img
                                class="col-sm-12 col-md-9"
                                v-if="data.DiagramUrl"
                                :src="getAzureUrl(getDiagramUrl(data.DiagramUrl))"
                                alt=""
                                :style="{
                                  width: `${getDiagramWidth(data.DiagramUrl) * 100}%`,
                                  height: `${getDiagramHeight(data.DiagramUrl) * 100}%`,
                                }"
                              />
                            </div>
                            <div
                              v-if="
                                data.AnswerOption_A &&
                                data.AnswerOption_B &&
                                data.AnswerOption_C &&
                                data.AnswerOption_D &&
                                data.Q_LATEX_Status === 'Y'
                              "
                            >
                              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                                <span v-if="!data.AnswerOption_A.includes('(A)')">(A)</span>
                                <vue-mathjax
                                  :formula="data.AnswerOption_A"
                                  :options="VueMathJaxOptions"
                                  :safe="false"
                                />
                              </h1>
                              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                                <span v-if="!data.AnswerOption_B.includes('(B)')">(B)</span>
                                <vue-mathjax
                                  :formula="data.AnswerOption_B"
                                  :options="VueMathJaxOptions"
                                  :safe="false"
                                />
                              </h1>
                              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                                <span v-if="!data.AnswerOption_C.includes('(C)')">(C)</span>
                                <vue-mathjax
                                  :formula="data.AnswerOption_C"
                                  :options="VueMathJaxOptions"
                                  :safe="false"
                                />
                              </h1>
                              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                                <span v-if="!data.AnswerOption_D.includes('(D)')">(D)</span>
                                <vue-mathjax
                                  :formula="data.AnswerOption_D"
                                  :options="VueMathJaxOptions"
                                  :safe="false"
                                />
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div
                          class="question-image1 relative"
                          :class="{
                            active: selectedQuestionIndex === index,
                            easy: data.DifficultyGroup === 'Easy',
                            normal: data.DifficultyGroup === 'Normal',
                            hard: data.DifficultyGroup === 'Hard',
                          }"
                          @click="questionDetails(data, index)"
                          v-else
                        >
                          <img
                            style="max-width: 650px; border-radius: 5px"
                            class="h-100 w-100"
                            :src="getAzureUrl(data.QuestionDiagramURL)"
                          />
                        </div>
                        <div
                          v-if="selectedQuestionIndex === index && showQuestionModal"
                          style="max-width: 432px"
                        >
                          <div style="margin-left: 13px">
                            <div
                              class="QuestionDetail relative"
                              style="background: #333333; border-radius: 4px 4px 0px 0px"
                            >
                              <div class="flex subject-title" style="padding: 15px 15px 5px">
                                Chapter:
                                <span class="subject-name" style="white-space: break-spaces"
                                  >&nbsp;{{ data.ChapterName }}</span
                                >
                              </div>
                              <div
                                class="flex subject-title"
                                style="padding: 0px 15px 5px 15px"
                                v-if="data.KSCClusterName"
                              >
                                Concept:
                                <span class="subject-name" style="white-space: break-spaces"
                                  >&nbsp;{{ data.KSCClusterName }}</span
                                >
                              </div>
                              <div class="flex subject-title" style="padding: 0px 15px 5px 15px">
                                <span
                                  class="subject-name"
                                  style="white-space: break-spaces; font-style: italic"
                                  >Click one of the following to replace this question</span
                                >
                              </div>
                            </div>
                            <button
                              class="manual-qns-btn active"
                              style="border-radius: 0 0 5px 5px; width: 100%; padding: 8px"
                              @click="
                                replaceQuestion(
                                  data.QuestionDiagramURL,
                                  data.CourseChapterId,
                                  subjectName,
                                  data.DifficultyGroup,
                                  data.ChapterName,
                                  data,
                                  index,
                                );
                                isReplaceMode = true;
                                qnsTypeIndex = 1;
                              "
                            >
                              <span style="margin-top: 5px">Replace Question</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      class="draft-btn fs-12 fw-600 text-center uppercase white-text"
                      @click="addQuestion()"
                      v-if="questionList.length !== sectionTotalQns && !showInstruction"
                      >Add More Question</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Manual Section -->
      <div class="white absolute w-100 manual-section" :class="{ active: openSlider }">
        <div
          style="
            height: 58px;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            border-bottom: 1px solid #e0e4f0;
            align-items: center;
          "
        >
          <div>
            <h6 style="font-size: 18px; font-weight: 700">
              {{ isAddMode ? "Add Questions" : "Edit Question" }}
            </h6>
          </div>
          <div>
            <img
              :src="require(`../assets/modalClose.svg`)"
              style="padding-top: 15px; padding-right: 15px; padding-bottom: 13px; cursor: pointer"
              @click="cancelQnsModal()"
            />
          </div>
        </div>
        <div class="manual-content scroll-verticle">
          <div
            style="padding-top: 20px; padding-bottom: 20px; padding-left: 33px; display: flex"
            v-if="!isAddMode"
          >
            <div>
              <img
                :src="getAzureUrl(this.previewQuestion)"
                style="max-width: 651px; width: 100%; max-height: 100%"
              />
            </div>
            <div
              class="QuestionDetail relative"
              style="
                background: #333333;
                border-radius: 4px 4px 4px 4px;
                margin-left: 13px;
                max-height: 115px;
                margin-right: 60px;
              "
            >
              <div style="display: flex; justify-content: space-between; padding-top: 15px">
                <div style="font-size: 12px; font-weight: 600; padding-left: 20px; color: white">
                  Subject:
                  <span style="font-size: 12px; font-weight: 400; color: white">{{
                    modelSecSubName
                  }}</span>
                </div>
                <div style="font-size: 12px; font-weight: 600; padding-left: 20px; color: white">
                  Difficulty Level:
                  <span
                    style="font-size: 12px; font-weight: 400; padding-right: 18px; color: white"
                    >{{ modelDiffiGroup }}</span
                  >
                </div>
              </div>
              <div style="padding-bottom: 10px; padding-top: 10px; padding-right: 10px">
                <div style="font-size: 12px; font-weight: 600; padding-left: 20px; color: white">
                  Chapter:
                  <span style="font-size: 12px; font-weight: 400; color: white">{{
                    modelKscName
                  }}</span>
                </div>
              </div>
              <div style="padding-bottom: 16px; padding-right: 10px">
                <div style="font-size: 12px; font-weight: 600; padding-left: 20px; color: white">
                  Concept:
                  <span style="font-size: 12px; font-weight: 400; color: white">{{
                    selectedQuestionData.KSCClusterName
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex h-100" style="padding: 20px 25px 0px 20px">
            <!-- <div class="flex" style="padding: 20px 25px 0px 20px;height: 675px;"> -->
            <div class="filter-sec white">
              <div class="filter-body">
                <div class="w-100 h-100 white radius-5">
                  <p class="fs-16 fw-700 dark-font" style="padding-left: 15px">Filter</p>

                  <div
                    class="flex flex-column scroll-verticle-bar"
                    style="max-height: calc(100vh - 387px)"
                  >
                    <div class="filter-item filter-chapter">
                      <CommonFilterComponent
                        id="chapterFilter"
                        :entityDataObject="ChapterDetails[currSecIndex]"
                        :showAll="false"
                        :isSingleSelect="true"
                        :key="selCourseChapterId"
                        :selectedId="selCourseChapterId"
                        @selectedEntityId="getSelectedChapterData($event)"
                        v-if="ChapterDetails[currSecIndex] && selCourseChapterId"
                      />
                    </div>
                    <div class="filter-item filter-chapter" v-if="qnsTypeIndex == 1">
                      <CommonFilterComponent
                        id="chapterFilter"
                        :entityDataObject="
                          Object.keys(kscClusterData).length > 0 ? kscClusterData : {}
                        "
                        :key="kscClusterData"
                        @selectedEntityId="filterByKscCluster($event)"
                        v-if="Object.keys(kscClusterData).length > 0"
                      />
                    </div>
                    <DifficultLevelComponent
                      :selectedClusterIdQuestionslist="ManualQnsData"
                      :key="ManualQnsData"
                      @selectedDiffLevel="filterByDiff($event)"
                      v-if="qnsTypeIndex == 1"
                    />
                    <div
                      class="filter-item hide"
                      style="padding: 20px 20px 20px 17px"
                      v-if="qnsTypeIndex == 1"
                    >
                      <div class="flex flex-between">
                        <label class="pointer dark-text fs-13 fw-700" for="used-qns"
                          >Exclude Used Questions</label
                        >
                        <input
                          type="checkbox"
                          :checked="isExcludeUsedQns"
                          id="used-qns"
                          @change="excludeUsedQns()"
                          style="top: 3px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="filter-item"
                    style="padding: 20px 20px 20px 17px"
                    v-if="qnsTypeIndex === 1"
                  >
                    <div class="flex flex-between">
                      <label class="pointer dark-text fs-13 fw-700" for="past-year-qns"
                        >Past Year Questions</label
                      >
                      <input
                        type="checkbox"
                        v-model="isPastYearQns"
                        :checked="isPastYearQns"
                        id="past-year-qns"
                        class="pointer"
                        @change="pastYearQns()"
                        style="top: 3px"
                      />
                    </div>
                  </div>
                  <div class="filter-item" v-if="qnsTypeIndex === 1" style="padding: 15px">
                    <input
                      type="text"
                      v-model="pdfUuid"
                      placeholder="PDF Uuid"
                      class="input-control"
                      style="width: 100px; height: 30px"
                    />
                    <button
                      class="end-cancel-button"
                      style="width: 80px; height: 30px"
                      @click="submitPdfUUID()"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="question-sec">
              <div class="flex flex-between">
                <div
                  class="manual-middle w-100"
                  :style="isAddMode ? 'max-width: 733px; margin-right: 20px;' : ''"
                >
                  <div class="flex flex-between flex-wrap items-center">
                    <div class="flex items-center" style="gap: 10px; margin-bottom: 3px">
                      <a
                        class="manual-qns-btn"
                        :class="{ active: qnsTypeIndex === 1 }"
                        @click="getManualQns(1)"
                        >Manual</a
                      >
                      <a
                        class="manual-qns-btn"
                        :class="{ active: qnsTypeIndex === 2 }"
                        @click="getInstituteQns(2)"
                        >Institute</a
                      >
                      <div
                        class="flex items-center"
                        style="
                          width: 100%;
                          justify-content: space-around;
                          border: 1px solid #e0e4f0;
                          background: white;
                          border-radius: 5px;
                          border: 1px solid #e0e4f0;
                          padding: 3px 10px;
                        "
                        v-if="qnsTypeIndex === 1"
                      >
                        <span class="flex question-header flex-right">All Qs</span>
                        <label class="switch">
                          <input
                            type="checkbox"
                            @click="toggleButton($event)"
                            :checked="toggleChecked"
                          />
                          <div class="slider round"></div>
                        </label>
                        <span class="question-header">Test Qs</span>
                      </div>

                      <span
                        class="material-icons material-symbols-outlined pointer"
                        v-if="qnsTypeIndex === 2"
                        @click="getInstituteQns(2)"
                        title="Refresh"
                        >cached</span
                      >
                    </div>
                    <div class="flex">
                      <router-link
                        class="manual-qns-btn active"
                        :to="{ name: 'AddCustomQuestion' }"
                        target="_blank"
                        v-if="qnsTypeIndex === 2"
                        style="margin-right: 10px"
                        >Add Custom Question</router-link
                      >
                      <div
                        class="flex manual-qns-btn items-center"
                        style="width: 234px; height: 30px; padding: 4px 8px; padding-right: 0"
                      >
                        <img
                          :src="require(`../assets/sort 1.svg`)"
                          alt=""
                          class="pointer"
                          @click="sortQnsList()"
                        />
                        <span class="sort-by relative nowrap">Sort By</span>
                        <div class="select-box">
                          <div class="select-header" @click="showDropdown = !showDropdown">
                            <p class="fs-13 fw-600 m-0" style="color: #3751ff; margin-left: 5px">
                              {{ sortText }}
                            </p>
                            <img
                              :src="require(`../assets/downarrow-1.svg`)"
                              alt=""
                              style="margin-right: 5px"
                            />
                          </div>
                          <div
                            class="select-dropdown"
                            v-if="showDropdown && loadedQuestions.length > 0"
                          >
                            <p @click="sortQuestionList('Difficulty')" v-if="qnsTypeIndex !== 2">
                              Difficulty
                            </p>
                            <p @click="sortQuestionList('Concept')" v-if="qnsTypeIndex !== 2">
                              Concept
                            </p>
                            <p @click="sortQuestionList('Recently Uploaded')">Recently Uploaded</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="manual-bottom w-100" :style="!isAddMode ? 'max-height: 747px;' : ''">
                    <h3 class="fs-12 m-0 fw-600 dark-text" style="margin: 10px 0 15px">
                      Questions: {{ QuestionDetails.length }}
                    </h3>
                    <div
                      style="height: calc(100vh - 335px)"
                      class="scroll-verticle"
                      v-if="loadedQuestions.length > 0"
                    >
                      <ManualQuestion
                        v-for="item in loadedQuestions"
                        :key="item.QuestionId"
                        :showCheckbox="false"
                        :selectedDiffGrp="
                          item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                        "
                        :Difficulty="
                          item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                        "
                        :showAddBtn="isAddMode"
                        @replaceBtnTriggered="replaceQns($event)"
                        :showReplaceBtn="!isAddMode"
                        :qnsData="item"
                        :QuestionDetail="item"
                        @click="selectQns(item)"
                        :showAddedText="addedQnsList.includes(item.QuestionId) ? true : false"
                        :isQustionSelected="
                          isAddMode
                            ? addedQnsList.includes(item.QuestionId)
                              ? true
                              : false
                            : item.QuestionId === selQnsId
                            ? true
                            : false
                        "
                      />
                      <h3
                        class="fs-20 fw-600 skyblue-text pointer text-center"
                        v-if="QuestionDetails.length > loadedQnsLength"
                        @click="loadMoreQns()"
                      >
                        Load more ...
                      </h3>
                    </div>
                    <div
                      style="text-align: center; max-width: 450px"
                      class="flex items-center"
                      v-if="loadedQuestions.length === 0"
                    >
                      <span
                        class="material-icons material-symbols-outlined red-text"
                        style="font-size: 60px"
                        >error</span
                      >
                      <p
                        class="fs-18 red-text fw-600"
                        style="margin-left: 10px !important; text-align: left"
                      >
                        No questions available for the selected filters.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="padding: 10px; border-bottom: 1px solid #e0e4f0"
                  class="selected-que-detail"
                  v-if="isAddMode"
                >
                  <div class="top radius-5 flex flex-column items-center flex-center">
                    <img :src="require(`../assets/check-circle 1.svg`)" alt="" />
                    <h3
                      class="m-0 fs-12 fw-400 white-text truncate"
                      style="margin-top: 5px; max-width: 80%"
                    >
                      {{ currSectionName }}
                    </h3>
                    <h3 class="m-0 fs-14 fw-700 white-text" style="margin-top: 5px !important">
                      Question Selected
                    </h3>
                    <h3
                      class="m-0 top-counter radius-5 white fs-16 fw-700 skyblue-text text-center"
                    >
                      {{ serialNumber }} <span>/</span> {{ sectionTotalQns }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Manual Footer -->
    <div class="flex w-100 flex-between items-center white manual-footer">
      <div class="flex">
        <a
          class="draft-btn fs-12 fw-600 text-center uppercase white-text"
          @click="addMoreQuestion()"
          style="margin-right: 30px"
          v-if="openSlider && isAddMode"
          >Add Question</a
        >
        <a
          class="draft-btn fs-12 fw-600 text-center uppercase white-text"
          @click="editTest()"
          style="margin-right: 30px"
          v-if="!isAddMode"
          >Edit Test</a
        >
        <a
          class="publish-btn fs-12 fw-600 text-center uppercase"
          @click="openPublishModal()"
          v-if="!isAddMode"
          :class="{ disable: totalQuestions !== totalSelectedQuestions || isDraft === 'false' }"
          >Publish Now</a
        >
      </div>
      <a
        class="cencel-btn fs-12 fw-600 text-center uppercase"
        v-if="openSlider"
        @click="cancelQnsModal()"
        >Cancel</a
      >
      <a
        class="cencel-btn fs-12 fw-600 text-center uppercase"
        @click="cancelEditTest()"
        v-if="!openSlider"
        >Cancel</a
      >
    </div>
    <Modal3
      :show="publishTestModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="conceptModal = false"
    >
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex flex-between publish-head">
            <span class="fs-16 fw-700" style="margin-top: 2px">Publish Test</span>
            <div @click="publishTestModal = false" style="cursor: pointer">
              <span class="material-icons publish-close" style="color: #ffffff !important"
                >close</span
              >
            </div>
          </div>
          <div class="modal-content">
            <div class="flex flex-between">
              <div class="flex column">
                <span class="fs-14 fw-700">Test Name</span>
                <input
                  type="text"
                  v-model="title"
                  placeholder="Test Name"
                  style="border-bottom: 1px solid #e0e4f0 !important"
                />
              </div>
            </div>
            <div class="flex flex-between">
              <div class="flex column" style="margin-top: 10px">
                <span class="fs-14 fw-700">Start DateTime</span>
                <v-date-picker
                  v-model="testStartDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="flex column" style="margin-top: 10px">
                <span class="fs-14 fw-700">End DateTime</span>
                <v-date-picker
                  v-model="testEndDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="flex column" style="margin-top: 10px">
              <span class="fs-14 fw-700 mb8">Duration <span class="red-text">*</span></span>
              <div class="flex flex-row mt8">
                <button
                  class="duration-button"
                  :class="{ selected: duration === '30' }"
                  @click="selectTime('30')"
                  value="30"
                >
                  30min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '45' }"
                  @click="selectTime('45')"
                  value="45"
                >
                  45min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '60' }"
                  @click="selectTime('60')"
                  value="60"
                >
                  60min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '90' }"
                  @click="selectTime('90')"
                  value="90"
                >
                  1hr 30min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '120' }"
                  @click="selectTime('120')"
                  value="120"
                >
                  2hrs
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '180' }"
                  @click="selectTime('180')"
                  value="180"
                >
                  3hrs
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '200' }"
                  @click="selectTime('200')"
                  value="200"
                >
                  3hr 20min
                </button>
              </div>
            </div>
            <div class="flex column" style="margin-top: 10px">
              <div class="flex flex-column" style="margin-top: 10px">
                <div>
                  <div>
                    <span class="fs-14 fw-700">Batches <span class="red-text">*</span></span>
                  </div>
                  <div class="course-btn-container" v-if="this.batchListNew.length > 0">
                    <div class="scroll-section">
                      <div v-for="(data, index1) in this.batchListNew" :key="index1" class="mt10">
                        <div class="flex flex-between items-center" style="padding-right: 20px">
                          <p class="subject-name" v-if="data.batches.length > 0">
                            {{ data.AffiliationName }}
                          </p>
                          <div class="select-all flex items-center" v-if="data.batches.length > 0">
                            <input
                              type="checkbox"
                              :id="index1 + 'chapter'"
                              class="pointer publish-input"
                              :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                              @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                              name="chapter"
                            />
                            <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                              >Select All</label
                            >
                          </div>
                        </div>
                        <div class="flex mt10" style="flex-wrap: wrap">
                          <button
                            class="course-button"
                            :class="{ selected: batchIdList.includes(topic) }"
                            v-for="(topic, index) in data.batches"
                            :key="index"
                            @click="onSelectBatch(topic, data.Name, index1)"
                          >
                            {{ topic.Name }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex column" style="margin-top: 10px">
              <div class="flex">
                <input
                  type="checkbox"
                  id="shuffle'"
                  class="pointer"
                  :checked="isShuffleQuestion === 1"
                  @change="shuffleQuestion($event)"
                  name="chapter"
                />
                <label
                  for="shuffle'"
                  class="pointer fs-14 fw-700"
                  style="color: #333333 !important; margin-left: 5px"
                  >Shuffle Questions</label
                >
              </div>
            </div>
            <span v-if="batchError === true" style="font-size: 11px; font-style: italic; color: red"
              >Please Select at least one batch</span
            >
            <span
              v-if="questionsError === true"
              style="font-size: 11px; font-style: italic; color: red"
              >Please Select all the questions to publish the test</span
            >
            <div class="flex items-center" style="padding-top: 10px">
              <button class="publish-modal-button" @click="publishTest()">Publish</button>
              <button
                class="cencel-btn"
                style="font-size: 12px; font-weight: 600"
                @click="publishTestModal = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal
      :show="showPdfUidModal"
      :showHeader="false"
      :showCloseBtn="false"
      :width="'100%'"
      :height="'100%'"
      :maxHeightBody="'800px'"
    >
      <template v-slot:body>
        <div class="flex flex-between publish-head">
          <span class="fs-16 fw-700" style="margin-top: 2px">Select PDF Questions</span>
          <div @click="showPdfUidModal = false" style="cursor: pointer">
            <span class="material-icons publish-close" style="color: #ffffff !important"
              >close</span
            >
          </div>
        </div>
        <div class="modalHeader flex flex-between" style="border-bottom: 1px solid #e0e4f0">
          <div>
            <h6 style="font-size: 18px; font-weight: 700; padding-left: 28px">PDF Questions</h6>
          </div>
        </div>
        <div class="flex flex-between" style="padding-left: 28px; padding-right: 25px">
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Total Questions: {{ this.pdfQuestions.length }}
            </h6>
          </div>
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Question Selected: {{ serialNumber }}
            </h6>
          </div>
          <!-- <div style="margin-top: 11px; margin-bottom: 7px;"><button class="loadMoreQuestionsButton" @click="loadMoreQuestions()">Load More Questions</button></div> -->
        </div>
        <div class="row">
          <div class="w-100 col s12 l9">
            <div class="scroll-verticle-bar" v-if="pdfQuestions.length > 0">
              <ManualQuestion
                v-for="item in pdfQuestions"
                :key="item.QuestionId"
                :selectedDiffGrp="
                  item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                "
                :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                :QuestionDetail="item"
                @click="selectQns(item)"
                :isQustionSelected="
                  isAddMode
                    ? addedQnsList.includes(item.QuestionId)
                      ? true
                      : false
                    : item.QuestionId === selQnsId
                    ? true
                    : false
                "
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import MobileApi from "../Mobileapi";
import TestInstructionComponentVue from "../components/TestInstructionComponent.vue";
import Modal3 from "../components/Modal3.vue";
import Modal from "../components/Modal.vue";
import ManualQuestion from "../components/ManualQuestion.vue";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";
import DifficultLevelComponent from "../components/DifficultLevelComponent.vue";

const toast = useToast();

export default {
  data() {
    return {
      instituteTestId: null,
      showInstruction: true,
      kscClusterData: {},
      showChapterFilter: true,
      showDifficultyFilter: true,
      showKscClusterFilter: true,
      isExcludeUsedQns: false,
      isPastYearQns: false,
      selKscClusterId: [],
      sectionList: [],
      sectionId: null,
      questionList: [],
      quesitonCount: null,
      modalQuestionData: [],
      selectedQuestionIndex: null,
      showQuestionModal: false,
      subjectName: "",
      kscList: [],
      questionReplacementList: [],
      selectedQuestionData: [],
      replacedQuestionData: [],
      isDifficultyTriggered: false,
      openSlider: false,
      ChapterDetails: [],
      currSecIndex: 0,
      selectedDiffGrp: [],
      ManualQnsData: [],
      QuestionDetails: [],
      loadMoreTriggered: false,
      selChapterId: null,
      selQnsId: null,
      sortText: "Select",
      diffiSortTrigger: true,
      kscClusterSortTrigger: true,
      recentlyUploadedSortTrigger: true,
      selectedCourseId: null,
      selCourseChapterId: null,
      batches: null,
      qnsTypeId: null,
      showAutomaticTest: true,
      qnsTypeIndex: true,
      selSubjectId: null,
      marksPerQuestion: null,
      difficultyLevelId: null,
      instituteQnsData: [],
      affiliationId: null,
      recommendedData: [],
      publishTestModal: false,
      minDate: moment().utcOffset("-00:00").startOf("day").toDate(),
      maxDate: moment().utcOffset("-00:00").add(3, "months").toDate(),
      startDate: moment().utcOffset("-00:00").add(30, "minutes").toDate(),
      endDate: moment().utcOffset("-00:00").add(7, "days").toDate(),
      testStartDate: "",
      testEndDate: "",
      publishBatches: [],
      batchIdList: [],
      isShuffleQuestion: 0,
      duration: "",
      title: "",
      batchError: false,
      totalQuestions: null,
      totalSelectedQuestions: null,
      questionsError: false,
      testDetails: [],
      isAddMode: false,
      isReplaceMode: false,
      addedQnsList: [],
      loadedQnsLength: 20,
      questionIdCsv: [],
      serialNumber: null,
      tempSerialNumber: null,
      sectionTotalQns: null,
      currSectionName: "",
      testSummary: [],
      modelSecSubName: "",
      modelDiffiGroup: "",
      modelKscName: "",
      previewQuestion: "",
      showDropdown: false,
      temporaryQnsList: null,
      isDraft: null,
      FilteredQnsList: [],
      qnsCategoryId: 1,
      toggleChecked: false,
      batchListNew: [],
      showPdfUidModal: false,
      pdfUuid: "",
      pdfQuestions: [],
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
    };
  },
  created() {
    this.affiliationId = this.$store.getters.user.AffiliationId;
    this.instituteTestId = this.$route.params.testId;
    this.isDraft = this.$route.params.IsDraft?.trim();
    this.$store.dispatch("showLoader", true);
    MobileApi.getInstituteTestSummary(
      {
        instituteTestId: this.instituteTestId,
      },
      (response) => {
        this.testSummary = response.data;
        sessionStorage.setItem("summaryData", JSON.stringify(this.testSummary));
        this.$store.dispatch("showLoader", false);
        if (this.testSummary !== null || this.testSummary !== undefined) {
          this.getSummaryData(this.testSummary);
        }
      },
    );
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  components: {
    TestInstructionComponentVue,
    Modal3,
    ManualQuestion,
    CommonFilterComponent,
    DifficultLevelComponent,
    Modal,
  },
  watch: {
    startDate(newVal) {
      this.testStartDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
    endDate(newVal) {
      this.testEndDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
  },
  methods: {
    submitPdfUUID() {
      debugger;
      const courseChapterIds = this.sectionList[this.currSecIndex].CourseChapterIds;
      const data = {
        courseChapterIds,
        questionType: this.sectionList[this.currSecIndex].QuestionTypeId,
      };
      const pdfId = this.pdfUuid;
      MobileApi.getQuestionsFromQuestionBankPdf(pdfId, data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.pdfQuestions = response.data;
        this.showPdfUidModal = true;
      });
    },
    groupBatchesByAffiliation(chapterlist) {
      debugger;
      const batchListTemp = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of chapterlist) {
        const affiliationId = chapter.AffiliationId;

        if (!batchListTemp[affiliationId]) {
          batchListTemp[affiliationId] = {
            AffiliationId: affiliationId,
            AffiliationName: chapter.AffiliationName,
            batches: [],
          };
        }

        batchListTemp[affiliationId].batches.push({
          Id: chapter.Id,
          Code: chapter.Code,
          Name: chapter.Name,
          AffiliationId: affiliationId,
        });
      }

      this.batchListNew = Object.values(batchListTemp);
      console.log(this.batchListNew);
    },
    checkIsAllBatchSelected(index, clusterId) {
      debugger;
      let returnData = false;
      const batchListTemp = this.batchIdList.filter((a) => a.AffiliationId === clusterId);
      const batchObj = this.batchListNew.filter((a) => a.AffiliationId === clusterId)[0];
      // ///this.tempClusterList = this.clusterListNew.filter((chapter) => batchListTemp.includes(chapter.Id));
      // const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      // const selectedChapters = this.selectedKscClusterIds.filter((a) => a.CourseChapterId === clusterId);
      if (
        batchListTemp != null &&
        batchObj.batchList != null &&
        batchObj.batchList.length > 0 &&
        batchListTemp.length > 0
      ) {
        if (batchObj.batchList.length === batchListTemp.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    selectAllBatch(event, chapterData, index, subjectId) {
      debugger;
      if (event.target.checked) {
        const topicList = chapterData.batches.flat();
        topicList.forEach((item) => {
          if (!this.batchIdList.includes(item)) {
            this.batchIdList.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.batchIdList.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.batchIdList.length - 1; i >= 0; i--) {
            if (this.batchIdList[i].AffiliationId === subjectId) {
              this.batchIdList.splice(i, 1);
            }
          }
        }
      }
      this.checkIsAllBatchSelected(index, chapterData.AffiliationId);
    },
    onSelectBatch(selectedBatch, index) {
      debugger;
      this.chapterCountList = [];
      if (this.batchIdList.includes(selectedBatch)) {
        const index1 = this.batchIdList.indexOf(selectedBatch);
        this.batchIdList.splice(index1, 1);
      } else {
        this.batchIdList.push(selectedBatch);
      }
      this.checkIsAllBatchSelected(index, selectedBatch.AffiliationId);
    },
    getSelectedChapterData(chapterId) {
      //
      this.selCourseChapterId = chapterId;
      if (this.openSlider) {
        if (this.qnsTypeIndex === 1) {
          this.replaceQuestion(
            this.previewQuestion,
            chapterId,
            this.modelSecSubName,
            this.modelDiffiGroup,
            this.modelKscName,
            this.selectedQuestionData,
          );
        } else {
          this.getInstituteQns(2);
        }
      }
    },
    getSummaryData(summaryData) {
      this.sectionList = [];
      this.ChapterDetails = [];
      this.recommendedData = [];
      this.temporaryQnsList = [];
      this.totalQuestions = null;
      this.totalSelectedQuestions = null;
      this.sectionList = summaryData.testSectionDetails;
      const courseChapterDetails = summaryData.courseChapterDetails;
      const ChapterDetails = [];
      this.selectedCourseId = summaryData.testDetails[0].CourseId;
      this.batches = summaryData.testDetails[0].Batches;
      this.title = summaryData.testDetails[0].Title;
      this.duration = String(summaryData.testDetails[0].Duration);
      this.testDetails = summaryData.testDetails[0];
      this.sectionList.forEach((item) => {
        ChapterDetails.push([]);
        this.totalQuestions += item.NoOfQuestions;
        this.totalSelectedQuestions += item.SelectedQuestions;
      });
      this.sectionList.forEach((item, index) => {
        courseChapterDetails.forEach((item1) => {
          if (item.InstituteTestPartId === item1.InstituteTestPartId) {
            ChapterDetails[index].push({
              ChapterName: item1.ChapterName,
              CourseChapterId: item1.CourseChapterId,
              ChapterId: item1.ChapterId,
              entityId: item1.CourseChapterId,
              entityName: item1.ChapterName,
            });
          }
        });
        this.ChapterDetails.push({
          Name: "Chapter",
          entityArray: ChapterDetails[index],
        });
      });
      this.getInstituteTestQnsList(1);
      this.getInstituteTestQnsList(2);
    },
    getInstituteTestQnsList(option) {
      this.$store.dispatch("showLoader", true);
      MobileApi.ViewInstituteTestQuestions(
        {
          instituteTestId: this.instituteTestId,
        },
        (question) => {
          let recommendedData;
          let tempRecommendedData;
          if (option === 1) {
            recommendedData = question.data;
          } else {
            tempRecommendedData = question.data;
          }
          const newSectionList = [];
          const tempNewSectionList = [];
          this.sectionList.forEach((item1, index) => {
            if (option === 1) {
              newSectionList.push([]);
              recommendedData.forEach((item2) => {
                if (item1.InstituteTestPartId === item2.InstituteTestPartId) {
                  newSectionList[index].push(item2);
                }
              });
            } else {
              tempNewSectionList.push([]);
              tempRecommendedData.forEach((item2) => {
                if (item1.InstituteTestPartId === item2.InstituteTestPartId) {
                  tempNewSectionList[index].push(item2);
                }
              });
            }
          });
          if (option === 1) {
            this.recommendedData = newSectionList;
          } else {
            this.temporaryQnsList = tempNewSectionList;
          }
          if (
            this.isAddMode &&
            this.temporaryQnsList.length > 0 &&
            this.recommendedData.length > 0
          ) {
            this.goToSection(this.sectionList[this.currSecIndex], this.currSecIndex);
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectTime(time) {
      this.duration = time;
    },
    goToSection(sectionData, index) {
      this.currSecIndex = index;
      // this.selCourseChapterId = null;
      this.showInstruction = false;
      this.isDifficultyTriggered = false;
      this.selectedQuestionIndex = null;
      this.selSubjectId = sectionData.SubjectId;
      this.qnsTypeId = sectionData.QuestionTypeId;
      this.sectionId = sectionData.InstituteTestPartId;
      this.marksPerQuestion = sectionData.MarksPerQuestion;
      this.difficultyLevelId = sectionData.DifficultyLevelId;
      this.subjectName = sectionData.SubjectName;
      this.questionIdCsv = [];
      this.addedQnsList = [];
      this.QuestionDetails = [];
      this.currSectionName = sectionData.SectionName;
      this.serialNumber = sectionData.SelectedQuestions;
      this.tempSerialNumber = this.serialNumber;
      this.sectionTotalQns = sectionData.NoOfQuestions;
      this.selCourseChapterId =
        this.ChapterDetails[this.currSecIndex].entityArray[0].CourseChapterId;
      this.selChapterId = this.ChapterDetails[this.currSecIndex].entityArray[0].ChapterId;
      this.questionList = this.recommendedData[this.currSecIndex].filter(
        (a) => a.InstituteTestPartId === sectionData.InstituteTestPartId,
      );
      // if (Object.keys(this.recommendedData[this.currSecIndex]).length > 1) {
      // } else {

      // }
      this.isAddMode = null;
      this.openSlider = false;
      // this.updateChapterId();
    },
    filterByDiff(diffGrpData) {
      this.isDifficultyTriggered = true;
      this.QuestionDetails = [];
      this.sortText = "Select";
      this.selectedDiffGrp = diffGrpData;
      let QuestionDetails = [];
      QuestionDetails = this.ManualQnsData.filter((item1) =>
        this.selectedDiffGrp.some(
          (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
        ),
      );
      this.QuestionDetails = QuestionDetails.filter((item1) =>
        this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
      );
      this.loadedQnsLength = 20;
      this.FilteredQnsList = [...this.QuestionDetails];
      if (this.isPastYearQns) {
        this.pastYearQns();
      }
      this.sortText = "Select";
    },
    filterByKscCluster(kscClusterId) {
      this.QuestionDetails = [];
      this.selKscClusterId = kscClusterId;
      this.loadedQnsLength = 20;
      this.sortText = "Select";
      this.QuestionDetails = this.ManualQnsData.filter((item1) =>
        this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
      );
      if (this.isDifficultyTriggered) {
        this.QuestionDetails = this.QuestionDetails.filter((item1) =>
          this.selectedDiffGrp.some(
            (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
          ),
        );
      }
      this.FilteredQnsList = [...this.QuestionDetails];
      if (this.isPastYearQns) {
        this.pastYearQns();
      }
      this.sortText = "Select";
    },
    excludeUsedQns() {
      this.isExcludeUsedQns = !this.isExcludeUsedQns;
    },
    pastYearQns() {
      if (this.qnsTypeIndex === 1) {
        const QuestionDetails = this.QuestionDetails;
        if (this.isPastYearQns) {
          this.QuestionDetails = QuestionDetails.filter((item) => item.IsPastYearQuestion === 1);
        } else if (!this.isPastYearQns) {
          this.QuestionDetails = this.ManualQnsData.filter((item1) =>
            this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
          );
        }
        if (this.selKscClusterId.length > 0) {
          this.QuestionDetails = this.QuestionDetails.filter((item1) =>
            this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
          );
        }
        if (this.isDifficultyTriggered) {
          this.QuestionDetails = this.QuestionDetails.filter((item1) =>
            this.selectedDiffGrp.some(
              (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
            ),
          );
        }
        this.FilteredQnsList = [...this.QuestionDetails];
        this.sortText = "Select";
      }
    },

    questionDetails(data, index) {
      this.selectedQuestionIndex = index;
      this.modalQuestionData = data;
      this.showQuestionModal = true;
      this.modelKscName = data.ChapterName;
    },
    showTestInstruction() {
      this.showInstruction = true;
      this.sectionId = null;
    },
    getManualQns(tabIndex) {
      this.isPastYearQns = false;
      this.isDifficultyTriggered = false;
      this.loadedQnsLength = 20;
      this.qnsTypeIndex = tabIndex;
      this.replaceQuestion(
        this.previewQuestion,
        this.selCourseChapterId,
        this.modelSecSubName,
        this.modelDiffiGroup,
        this.modelKscName,
        this.selectedQuestionData,
      );
    },
    replaceQuestion(qnsUrl, courseChapterId, subName, diffiGrp, kscName, data) {
      this.openSlider = true;
      this.ManualQnsData = [];
      this.selectedDiffGrp = [];
      this.loadedQnsLength = 20;
      this.isDifficultyTriggered = false;
      this.sortText = "Select";
      this.showDropdown = false;
      // this.showAutomaticTest = false;
      this.selectedQuestionData = data;
      this.previewQuestion = qnsUrl;
      this.modelSecSubName = subName;
      this.modelDiffiGroup = diffiGrp;
      this.modelKscName = kscName;
      this.sortText = "Select";
      this.showDropdown = false;
      this.selCourseChapterId = courseChapterId;
      this.$store.dispatch("showLoader", true);
      MobileApi.getCreatTestManualQuestion(
        {
          courseId: this.selectedCourseId,
          batches: this.batches,
          courseChapterId,
          isManual: 1,
          questionType: this.qnsTypeId,
          questionCategoryId: this.qnsCategoryId,
          instituteTestId: this.instituteTestId ? this.instituteTestId : 0,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          const ManualQnsData = response.data;
          this.ManualQnsData = ManualQnsData.map((item) => ({
            ...item,
            CreatedOn: item.CreatedOn ? moment(item.CreatedOn).format("X") : 0,
          }));
          const kscClusterData = Array.from(
            new Set(this.ManualQnsData.map((item) => item.KSCClusterId)),
          ).map((KSCClusterId) => ({
            entityId: KSCClusterId,
            entityName: this.ManualQnsData.find((item) => item.KSCClusterId === KSCClusterId)
              .KSCClusterName,
          }));
          this.kscClusterData = {
            Name: "Concept",
            entityArray: kscClusterData,
          };
          if (this.ManualQnsData.length > 0) {
            this.QuestionDetails = this.ManualQnsData;
            this.FilteredQnsList = [...this.QuestionDetails];
          } else {
            this.QuestionDetails = [];
            this.$store.dispatch("showLoader", false);
          }
          this.$store.dispatch("showLoader", false);
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    getInstituteQns(tabIndex) {
      this.qnsTypeIndex = tabIndex;
      this.QuestionDetails = [];
      this.loadedQnsLength = 20;
      this.isDifficultyTriggered = false;
      this.sortText = "Select";
      this.showDropdown = false;
      this.$store.dispatch("showLoader", true);
      MobileApi.getCreatTestManualQuestion(
        {
          courseId: this.selectedCourseId,
          batches: this.batches,
          courseChapterId: this.selCourseChapterId,
          isManual: 0,
          questionType: this.qnsTypeId,
          instituteTestId: this.instituteTestId ? this.instituteTestId : 0,
        },
        (resp) => {
          this.$store.dispatch("showLoader", false);
          this.instituteQnsData = resp.data;
          if (this.instituteQnsData.length > 0) {
            this.QuestionDetails = this.instituteQnsData.map((item) => ({
              ...item,
              DifficultyGroup: "easy",
              CreatedOn: item.CreatedOn ? moment(item.CreatedOn).format("X") : 0,
            }));
            this.instituteQnsData = this.QuestionDetails;
            this.FilteredQnsList = [...this.QuestionDetails];
          } else {
            this.QuestionDetails = [];
            this.$store.dispatch("showLoader", false);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    toggleButton(event) {
      this.toggleChecked = event.target.checked;
      if (this.toggleChecked) {
        this.qnsCategoryId = 5;
      } else {
        this.qnsCategoryId = 1;
      }
      this.getManualQns(1);
    },
    selectQns(qnsData) {
      if (this.isAddMode) {
        if (this.addedQnsList.includes(qnsData.QuestionId)) {
          const qnsIndex = this.addedQnsList.indexOf(qnsData.QuestionId);
          if (qnsIndex > -1) {
            this.addedQnsList.splice(qnsIndex, 1);
            this.serialNumber -= 1;
            this.questionIdCsv.forEach((item, index) => {
              if (item.NewQuestionId === qnsData.QuestionId) {
                this.questionIdCsv.splice(index, 1);
              }
            });
          }
        } else if (this.serialNumber < this.sectionTotalQns) {
          this.addedQnsList.push(qnsData.QuestionId);
          this.serialNumber += 1;
          this.questionIdCsv.push({
            OldQuestionId: null,
            NewQuestionId: qnsData.QuestionId,
            SectionId: this.sectionId,
            SerialNumber: this.serialNumber,
            InstituteTestQuestionId: 0,
          });
        }
      } else {
        this.selQnsId = qnsData.QuestionId;
      }
    },
    sortQuestionList(sortText) {
      this.sortText = sortText;
      this.sortQnsList();
    },
    sortQnsList() {
      if (this.sortText === "Difficulty") {
        if (this.diffiSortTrigger) {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.DifficultyScaled - b.DifficultyScaled,
          );
          this.diffiSortTrigger = false;
        } else {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.DifficultyScaled - a.DifficultyScaled,
          );
          this.diffiSortTrigger = true;
        }
      }
      if (this.sortText === "Concept") {
        if (this.kscClusterSortTrigger) {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.DisplayRank - b.DisplayRank,
          );
          this.kscClusterSortTrigger = false;
        } else {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.DisplayRank - a.DisplayRank,
          );
          this.kscClusterSortTrigger = true;
        }
      }
      if (this.sortText === "Recently Uploaded") {
        if (this.recentlyUploadedSortTrigger) {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.CreatedOn - b.CreatedOn,
          );
          this.recentlyUploadedSortTrigger = false;
        } else {
          this.QuestionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.CreatedOn - a.CreatedOn,
          );
          this.recentlyUploadedSortTrigger = true;
        }
      }
      this.showDropdown = false;
    },
    replaceQns(data) {
      this.replacedQuestionData = data;
      this.isPastYearQns = false;
      this.sortText = "Select";
      this.showDropdown = false;
      this.toggleChecked = false;
      this.qnsCategoryId = 1;
      const index = this.questionReplacementList.findIndex(
        (item) =>
          item.SerialNumber === this.selectedQuestionData.SerialNumber &&
          item.SectionId === this.sectionId,
      );
      if (index > -1) {
        this.questionReplacementList.splice(index, 1);
        this.questionReplacementList.push({
          OldQuestionId:
            this.temporaryQnsList[this.currSecIndex][this.selectedQuestionIndex].QuestionId,
          NewQuestionId: this.replacedQuestionData.QuestionId,
          SectionId: this.sectionId,
          SerialNumber: this.selectedQuestionData.SerialNumber,
          InstituteTestQuestionId: this.selectedQuestionData.InstituteTestQuestionId,
          CourseChapterId: this.replacedQuestionData.CourseChapterId,
          DifficultyLevel: this.replacedQuestionData.DifficultyGroup,
          KSCClusterId: this.replacedQuestionData.KSCClusterId,
        });
      } else {
        this.questionReplacementList.push({
          OldQuestionId:
            this.temporaryQnsList[this.currSecIndex][this.selectedQuestionIndex].QuestionId,
          NewQuestionId: this.replacedQuestionData.QuestionId,
          SectionId: this.sectionId,
          SerialNumber: this.selectedQuestionData.SerialNumber,
          InstituteTestQuestionId: this.selectedQuestionData.InstituteTestQuestionId,
          CourseChapterId: this.replacedQuestionData.CourseChapterId,
          DifficultyLevel: this.replacedQuestionData.DifficultyGroup,
          KSCClusterId: this.replacedQuestionData.KSCClusterId,
        });
      }
      this.questionList[this.selectedQuestionIndex].QuestionId =
        this.replacedQuestionData.QuestionId;
      this.questionList[this.selectedQuestionIndex].QuestionDiagramURL =
        this.replacedQuestionData.QuestionDiagramURL;
      this.questionList[this.selectedQuestionIndex].ChapterName =
        this.replacedQuestionData.ChapterName;
      this.questionList[this.selectedQuestionIndex].CourseChapterId =
        this.replacedQuestionData.CourseChapterId;
      this.questionList[this.selectedQuestionIndex].DifficultyGroup =
        this.replacedQuestionData.DifficultyGroup;
      this.questionList[this.selectedQuestionIndex].KSCClusterName =
        this.replacedQuestionData.KSCClusterName;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].QuestionId =
        this.replacedQuestionData.QuestionId;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].QuestionDiagramURL =
        this.replacedQuestionData.QuestionDiagramURL;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].ChapterName =
        this.replacedQuestionData.ChapterName;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].DifficultyGroup =
        this.replacedQuestionData.DifficultyGroup;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].KSCClusterName =
        this.replacedQuestionData.KSCClusterName;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].CourseChapterId =
        this.replacedQuestionData.CourseChapterId;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].Q_LATEX_Status =
        this.replacedQuestionData.Q_LATEX_Status;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].Q_Latex =
        this.replacedQuestionData.Q_LATEX;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].AnswerOption_A =
        this.replacedQuestionData.AnswerOption_A;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].AnswerOption_B =
        this.replacedQuestionData.AnswerOption_B;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].AnswerOption_C =
        this.replacedQuestionData.AnswerOption_C;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].AnswerOption_D =
        this.replacedQuestionData.AnswerOption_D;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].Q_LATEX_VERIFIED =
        this.replacedQuestionData.Q_LATEX_VERIFIED;
      this.recommendedData[this.currSecIndex][this.selectedQuestionIndex].DiagramUrl =
        this.replacedQuestionData.DiagramUrl;
      this.selectedQuestionData = this.replacedQuestionData;
      this.$store.dispatch("showLoader", true);
      MobileApi.replaceInstituteQuestions(
        {
          instituteTestId: this.instituteTestId,
          isTestType: 0,
          questionIdCsv: this.questionReplacementList,
        },
        (response) => {
          if (response.responseCode === 200) {
            this.QuestionDetails = [];
            this.ManualQnsData = [];
            this.loadMoreTriggered = false;
            this.showAutomaticTest = true;
          }
          this.openSlider = false;
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    addQuestion() {
      this.isPastYearQns = false;
      this.isAddMode = true;
      this.QuestionDetails = [];
      this.isPastYearQns = false;
      this.ManualQnsData = [];
      this.openSlider = true;
      this.qnsTypeIndex = 1;
      this.loadedQnsLength = 20;
      this.questionIdCsv = [];
      this.selectedDiffGrp = [];
      this.getManualQns(1);
    },
    addMoreQuestion() {
      this.loadMoreTriggered = false;
      this.$store.dispatch("showLoader", true);
      MobileApi.replaceInstituteQuestions(
        {
          instituteTestId: this.instituteTestId,
          isTestType: 1,
          questionIdCsv: this.questionIdCsv,
        },
        (response) => {
          if (response.responseCode === 200) {
            toast.success("Question added successfully!", {
              timeout: 4495,
            });
            // MobileApi.ViewInstituteTestQuestions({
            //   instituteTestId: this.instituteTestId,
            // }, (question) => {
            // this.recommendedData = question.data;
            MobileApi.getInstituteTestSummary(
              {
                instituteTestId: this.instituteTestId,
              },
              (res) => {
                this.testSummary = res.data;
                this.getSummaryData(this.testSummary);
              },
            );
            // });
          }
        },
      );
    },
    cancelQnsModal() {
      this.openSlider = false;
      this.isPastYearQns = false;
      this.QuestionDetails = [];
      this.ManualQnsData = [];
      this.loadMoreTriggered = false;
      this.isAddMode = null;
      this.serialNumber = this.tempSerialNumber;
      this.addedQnsList = [];
      this.kscClusterData = {};
      this.sortText = "Select";
      this.showDropdown = false;
      this.toggleChecked = false;
      this.qnsCategoryId = 1;
    },
    loadMoreQns() {
      this.loadedQnsLength += 20;
    },
    editTest() {
      this.$store.dispatch("showLoader", true);
      MobileApi.replaceInstituteQuestions(
        {
          instituteTestId: this.instituteTestId,
          isTestType: 0,
          questionIdCsv: this.questionReplacementList,
        },
        (response) => {
          if (response.responseCode === 200) {
            toast.success("Institute test updated successfully!", {
              timeout: 4000,
            });
            this.$store.dispatch("showLoader", false);
            this.$router.push({
              name: "ManageInstituteTest",
              params: { isNavigate: 0 },
            });
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    openPublishModal() {
      this.batchError = false;
      this.questionsError = false;
      this.publishTestModal = true;
      const tempBatchList = this.batches.split(",");
      this.testStartDate = moment(this.testDetails.StartDateTime, "YYYY-MM-DD h:mma").toDate();
      this.testEndDate = moment(this.testDetails.EndDateTime, "YYYY-MM-DD h:mma").toDate();
      // this.batchIdList.push(this.batches.split(","));
      this.batchIdList = this.batchIdList.map((item) => Number(item));
      this.$store.dispatch("showLoader", true);
      MobileApi.getBatches(
        {
          AffiliationId: this.$store.getters.user.AffiliationId,
          CourseId: this.selectedCourseId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.publishBatches = response.data;
          this.groupBatchesByAffiliation(this.publishBatches);
          debugger;
          // Iterate through the data array and extract batches based on Ids
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.batchListNew) {
            const batches = item.batches.filter((batch) =>
              tempBatchList.includes(String(batch.Id)),
            );
            this.batchIdList.push(...batches);
          }
        },
      );
    },
    cancelEditTest() {
      this.$router.push({ name: "ManageInstituteTest", params: { isNavigate: 1 } });
    },
    selectBatches(data) {
      if (this.batchIdList.includes(data.Id)) {
        const index = this.batchIdList.indexOf(data.Id);
        this.batchIdList.splice(index, 1);
      } else {
        this.batchIdList.push(data.Id);
      }
    },
    selectAllBatches(event) {
      if (event.target.checked) {
        this.batchIdList = this.publishBatches.map((item) => item.Id);
      } else {
        this.batchIdList = [];
      }
    },
    shuffleQuestion(event) {
      if (event.target.checked) {
        this.isShuffleQuestion = 1;
      } else {
        this.isShuffleQuestion = 0;
      }
    },
    publishTest() {
      this.$store.dispatch("showLoader", true);
      if (this.batchIdList.length < 1) {
        this.batchError = true;
        this.$store.dispatch("showLoader", false);
      } else {
        this.batchError = false;
      }
      if (this.totalQuestions !== this.totalSelectedQuestions) {
        this.questionsError = true;
        this.$store.dispatch("showLoader", false);
      } else {
        this.questionsError = false;
      }
      if (this.title.trim() === "") {
        toast.error("Please enter the test title");
        this.$store.dispatch("showLoader", false);
      } else if (
        this.batchIdList.length > 0 &&
        this.totalQuestions === this.totalSelectedQuestions
      ) {
        const data = {
          InstituteTestId: this.instituteTestId,
          StartDateTime: moment(this.testStartDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          EndDateTime: moment(this.testEndDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          Duration: Number(this.duration),
          BatchIds: this.batchIdList.map((a) => a.Id).join(","),
          Title: this.title,
          ShuffleQuestions: this.isShuffleQuestion,
        };
        MobileApi.PublishInstituteTest(data, (resp) => {
          if (resp.responseCode === 200) {
            MobileApi.replaceInstituteQuestions(
              {
                instituteTestId: this.instituteTestId,
                isTestType: 0,
                questionIdCsv: this.questionReplacementList,
              },
              (response) => {
                this.$store.dispatch("showLoader", false);
                if (response.responseCode === 200) {
                  this.publishTestModal = false;
                  toast.success("Institute test published Successfully", {
                    timeout: 4000,
                  });
                  this.$router.push({
                    name: "ManageInstituteTest",
                    params: { isNavigate: 0 },
                    query: { feedback: true },
                  });
                }
              },
            );
          } else if (resp.responseCode === 500) {
            toast.success(`${resp.data.errors[0]}`, {
              timeout: 4000,
            });
            this.$store.dispatch("showLoader", false);
          }
        });
      }
    },
    shuffleQuestionList() {
      // eslint-disable-next-line no-plusplus
      for (let i = this.questionList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = this.questionList[i];
        this.questionList[i] = this.questionList[j];
        this.questionList[j] = temp;
      }
      // eslint-disable-next-line no-plusplus
      const list = [];
      this.questionList.forEach((obj, index) => {
        // eslint-disable-next-line no-param-reassign
        obj.SerialNumber = index + 1;
        list.push({
          InstituteTestQuestionId: obj.InstituteTestQuestionId,
          InstituteTestId: Number(this.instituteTestId),
          SerialNumber: obj.SerialNumber,
          InstituteTestPartId: obj.InstituteTestPartId,
        });
      });
      const data = {
        instituteTestId: Number(this.instituteTestId),
        ShuffleQuestionsList: list,
      };
      MobileApi.shuffleQuestions(data, (response) => {
        // eslint-disable-next-line no-shadow
        this.recommendedData = response.data;
        const newSectionList = [];
        this.sectionList.forEach((item1, index) => {
          newSectionList.push([]);
          this.recommendedData.forEach((item2) => {
            if (item1.InstituteTestPartId === item2.InstituteTestPartId) {
              newSectionList[index].push(item2);
            }
          });
        });
        this.recommendedData = newSectionList;
      });
    },
    getDiagramUrl(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.url;
    },
    getDiagramWidth(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[2];
    },
    getDiagramHeight(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[3];
    },
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
  },
  computed: {
    loadedQuestions() {
      this.questionList.forEach((item1) => {
        const index = this.QuestionDetails.findIndex(
          (item2) => item2.QuestionId === item1.QuestionId,
        );
        if (index > -1) {
          this.QuestionDetails.splice(index, 1);
        }
      });
      const loadedQuestions = this.QuestionDetails.slice(0, this.loadedQnsLength);
      return loadedQuestions;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

/* custom css */
.automatic-test {
  font-family: "Open Sans" !important;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 100px);
}

.between {
  justify-content: space-between !important;
}

.pd-top18 {
  padding-top: 18px !important;
}

.pd-15 {
  padding: 15px !important;
}

.pd-left15 {
  padding-left: 15px !important;
}

.pd-right15 {
  padding-right: 15px !important;
}

.pd-bottom15 {
  padding-bottom: 15px !important;
}

.column {
  flex-direction: column !important;
}

.skyblue-text {
  color: #3751ff;
}

.dark-text {
  color: #333333;
}

.box-border {
  border: 1px solid #e0e4f0;
}

.box-border-right {
  border-right: 1px solid #e0e4f0;
}

.fs-14 {
  font-weight: 14px;
}

.fs-16 {
  font-weight: 16px;
}

.fw-700 {
  font-weight: 700;
}

.box-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

/* Main CSS */
.manual-top {
  border-radius: 5px 5px 0 0;
  height: 65px;
}

.main-head {
  margin: 25px 0 10px;
}

.heading {
  margin: 0 37px 0 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.heading.active {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #3751ff;
}

.heading.active::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #3751ff;
  left: 0;
  bottom: 0;
  border-radius: 3px;
}

.sections {
  max-width: 815px;
  min-width: 300px;
  width: 100%;
}

.manual-contents {
  height: calc(100vh - 235px);
  border-left: 1px solid #e0e4f0;
  border-right: 1px solid #e0e4f0;
}

.filter-sec {
  min-width: 215px;
}

.filter-body {
  border: 1px solid #e0e4f0;
  border-radius: 5px;
}

.question-sec {
  width: 100%;
}

.filter-item {
  border-top: 1px solid #e0e4f0;
  /* padding: 5px 20px 5px 15px;
        width: 215px; */
}

.filter-list {
  padding: 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 148px;
}

.filter-item .filter-list label {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  white-space: break-spaces;
  margin-left: 11px;
}

.filter-item:nth-child(2) .filter-list label {
  margin-left: 0;
}

.manual-middle {
  padding: 0 0 0 20px;
}

.manual-bottom {
  /* padding: 5px 0 15px; */
  /* max-width: 743px; */
  /* width: 100%;
      height: 58vh;
      overflow: hidden; */

  /* padding: 5px 0 15px; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  /* overflow: hidden; */
  flex-direction: column;
}

.manual-qns-btn {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  padding: 4px 15px;
  color: #333333;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.manual-qns-btn.active {
  background: #3751ff;
  color: white;
  border-color: #3751ff;
}

.shuffle-question-button {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  padding: 4px 15px;
  color: #333333;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.shuffle-question-button:hover {
  background: #3751ff;
  color: white;
  border-color: #3751ff;
}

.selected-que-detail {
  width: 198px;
  /* max-height: 458px; */
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
}

.chapter-content {
  padding: 15px 0;
  max-height: 185px;
}

.chapter-detail {
  padding: 10px 0;
  border-bottom: 1px solid #f3f5fc;
}

.chapter-detail:last-child {
  border-bottom: none;
}

.selected-que-detail .top {
  background: #5866c5;
  border-radius: 5px;
  width: 178px;
  height: 165px;
  border-bottom: 1px solid #e0e4f0;
}

.selected-que-detail .top .top-counter {
  width: 75px;
  height: 32px;
  border-radius: 5px;
  margin-top: 13px !important;
  line-height: 32px;
}

.selected-que-detail .bottom {
  padding: 20px 15px;
  /* max-height: 215px; */
  border-bottom: 1px solid #e0e4f0;
}

.selected-que-detail .bottom:last-child {
  border-bottom: none;
}

.sub-counter {
  width: 19px;
  height: 15px;
  background: #3751ff;
  border-radius: 2px;
  text-align: center;
  line-height: 15px;
}

.manual-footer {
  height: 80px;
  border: 1px solid #e0e4f0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 5px 5px;
  padding: 20px;
}

.draft-btn {
  width: 179px;
  height: 40px;
  background: #37841c;
  border-radius: 5px;
  line-height: 40px;
  display: block;
}

.draft-btn.add-btn {
  margin-right: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 110px;
  height: 30px;
  line-height: 30px;
}

.cencel-btn {
  width: 125px;
  height: 40px;
  border: 1px solid #ff7675;
  border-radius: 5px;
  color: #ff7675;
  line-height: 40px;
  text-transform: uppercase;
  background: white;
  cursor: pointer;
}

.filter-indicator {
  position: relative;
}

.filter-indicator::after {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  right: -18px;
  top: 5px;
}

.input-filter:nth-child(2) .filter-indicator::after {
  background: #16a085;
}

.input-filter:nth-child(3) .filter-indicator::after {
  background: #edbf6b;
}

.input-filter:nth-child(4) .filter-indicator::after {
  background: #ea7b7b;
}

.index-class {
  width: 35px;
  height: 35px;
  background: #999fb7;
  border-radius: 20px;
  margin-right: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.index-class.active {
  background: #333333 !important;
}

.question-image {
  border-radius: 5px;
  border: 1px solid #e0e4f0;
  cursor: pointer;
  max-width: 650px;
  min-width: 650px;
}

.question-image1 {
  border-radius: 5px;
  border: 1px solid #e0e4f0;
  cursor: pointer;
}

.question-image.active,
.question-image1.active {
  border: 1px solid #333333;
}

.question-image.easy::before,
.question-image1.easy::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #8bc32e !important;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}

.question-image.normal::before,
.question-image1.normal::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ffbf44 !important;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}

.question-image.hard::before,
.question-image1.hard::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ff835ce5 !important;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}

.subject-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.subject-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.count-button {
  background: #3751ff;
  color: white;
  width: 29px;
  height: 19px;
  padding-top: 3px;
  margin-top: 3px;
  text-align: center;
  border-radius: 19px;
}

.question-button {
  height: 28px;
  background: #f3f5fc;
  color: #3751ff;
  border-radius: 5px;
  border: none;
  width: 170px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  width: 18px;
}

#sort-select {
  background: url("../assets/downarrow-1.svg") no-repeat right;
  -webkit-appearance: none;
}

select:focus {
  outline: none;
}

button:focus {
  background-color: none;
}

.sort-by::before {
  position: absolute;
  content: "";
  height: 28px;
  width: 1px;
  background-color: #f3f5fc;
  right: -10px;
  top: -4px;
}

.manual-section {
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e4f0;
  border-bottom: none;
  top: 0;
  right: -100%;
  transition: 0.8s all linear;
  height: 100%;
}

.manual-section.active {
  right: 0;
}

.manual-content {
  position: relative;
  height: calc(100vh - 240px);
}

.QuestionDetail::before {
  position: absolute;
  width: 14px;
  height: 14px;
  content: "";
  background: #333333;
  left: 0px;
  top: 23px;
  margin-left: -4px;
  -webkit-backdrop-filter: #333333;
  backdrop-filter: #333333;
  transform: rotate(45deg);
  background: #333333;
}

.publish-btn {
  width: 179px;
  height: 40px;
  background: #f3f5fc;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  line-height: 40px;
  color: #3751ff;
}

.publish-btn.disable,
.publish-btn.draft-disable {
  background-color: #f1f1f1;
  pointer-events: none;
  color: #8e8e8e !important;
}

.concept-modal {
  max-width: 1173px !important;
  min-width: 650px !important;
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.modal-content {
  padding: 10px;
}

input[type="text"]:not(.browser-default) {
  width: 650px;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  /* margin: 0px !important; */
}

input[type="number"]:not(.browser-default) {
  width: 190px;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  /* margin: 0px !important; */
}

.course-button {
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
  background: #f3f5fc;
}

.selected {
  background: #3751ff !important;
  color: #ffffff !important;
}

.publish-modal-button {
  width: 130px;
  height: 40px;
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}

.duration-button {
  height: 28px;
  background: #f3f5fc;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.date-column {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px !important;
  width: 310px !important;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  background: #ffffff;
  width: 113px;
  height: 40px;
}

[type="radio"]:not(:checked),
[type="radio"]:checked,
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.filter-icon {
  font-size: 13px;
  font-weight: 700;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip {
  position: absolute;
  background: bisque;
  /* display: flex; */
  top: 21px;
  right: -24px;
}

.warning-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 0;
}

.tooltiptext {
  visibility: hidden;
  background-color: #f44336;
  color: white;
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  left: 138px;
  bottom: 11px;
  white-space: break-spaces;
  text-align: center;
  width: 190px;
  text-align: left;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;

  position: absolute;
  left: 8px;
  top: -8px;
  height: 40px;
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #f44336;
  left: -4px;
  top: 15px;
  /* margin-left: -2px; */
  -webkit-backdrop-filter: #f44336;
  backdrop-filter: #f44336;
  transform: rotate(45deg);
  background: #f44336;
  visibility: hidden;
  transition: 0.3s all ease;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}

.previewBody_content {
  width: 100%;
  height: 100%;
  border: 1px solid #e0e4f0;
  padding-left: 5px;
  padding-right: 5px;
  background: #f3f5fc !important;
  border-top: none;
  border-bottom: none;
  padding: 15px 15px 38px !important;
}

.select-box {
  width: 100%;
  margin-left: 10px;
  position: relative;
}

.select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.select-dropdown {
  position: absolute;
  width: 100%;
  top: 26px;
  background: white;
  left: 0;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  z-index: 99;
}

.select-dropdown p {
  margin: 0;
  padding: 0 5px;
  cursor: pointer;
}

.select-dropdown p:hover {
  color: #3751ff;
}

.question-header {
  height: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin: 0 7px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a9a9a98f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a9a9a98f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #a9a9a98f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.course-btn-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
